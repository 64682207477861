<template>
  <div>
    <div class="container">
      <div class="flex flex-row items-center mb-6" style="margin-top: 80px;">
        <div class="flex flex-row items-center w-1/2">
          <div class="border-red-600 border-r-4 h-10 w-1 mr-2"></div>
          <div class="sm:text-lg md:text-2xl lg:text-3xl font-bold">
            ความรู้ด้านเทคโนโลยี : อินโฟกราฟฟิก
          </div>
        </div>
      </div>

      <div
        class="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 mb-12"
      >
        <div v-for="(item, index) in items" :key="index">
          <div @click="toggleModal(item.img)">
            <img
              :src="`${item.img}`"
              class="w-full h-full object-cover"
              style="width: 100%; height: 283px"
            />
            <div class="description">
              {{ item.description }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div
        v-if="showModal"
        class="
          overflow-x-hidden overflow-y-auto
          fixed
          inset-0
          z-50
          outline-none
          focus:outline-none
          justify-top
          items-top
          flex
        "
      >
        <div class="relative w-auto my-6 mx-auto max-w-6xl">
          <!--content-->
          <div class="">
            <!--header-->
            <div class="text-2xl flex items-end justify-end text-white">
              <button
                class="border-2 p-1"
                type="button"
                v-on:click="toggleModal()"
              >
                X
              </button>
            </div>
            <!--body-->
            <div class="p-6">
              <p class="">
                <img
                  class="w-full h-full object-contain"
                  :src="`${img}`"
                  alt=""
                />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="showModal"
        class="opacity-75 fixed inset-0 z-40 bg-black"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import photo1 from "@/assets/mog/13_135851193_3642854925752358_3124211194642909778_o.jpg";
import photo2 from "@/assets/mog/12_129784420_3561469937224191_430125516635993757_o.jpg";
import photo3 from "@/assets/mog/10_MILDL_2019_Slide2_MIL_Score-1200.jpg";
import photo4 from "@/assets/mog/7_MILSolution.jpg";
import photo5 from "@/assets/mog/7_ict_brochure_design_1-6-5-01.jpg";
import photo6 from "@/assets/mog/5_2019-01-30-88-19-3113519.jpg";
export default {
  data() {
    return {
      imgModal: null,
      showModal: false,
    };
  },
  methods: {
    toggleModal: function (img) {
      this.img = img;
      this.showModal = !this.showModal;
    },
  },
  setup() {
    const items = ref([
      {
        img: photo1,
        description: "กองทุนดีอีเผยผลงานช่วยโรงพยาบาลฝ่าวิกฤตโควิดระลอกใหม่",
      },
      {
        img: photo2,
        description: "การพัฒนาดิจิทัลของประเทศไทย ปี 2563",
      },
      {
        img: photo3,
        description:
          "สถานภาพการู้เท่าทันสื่อและสารสนเทศของประเทศไทย ประจำปี พ.ศ. 2562",
      },
      {
        img: photo4,
        description: "สถานภาพการเข้าใจดิจิทัลของประเทศไทย ประจำปี พ.ศ. 2562",
      },
      {
        img: photo5,
        description:
          "รู้จักสำนักงานคณะกรรมการดิจิทัลเพื่อเศรษฐกิจและสังคมแห่งชาติ",
      },
      {
        img: photo6,
        description: "สถานภาพการเข้าใจดิจิทัลของประเทศไทย ประจำปี พ.ศ. 2562",
      },
    ]);
    return { items };
  },
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  text-decoration-line: underline;
  color: #3d3d3d;
}
</style>